<!--footer section -->
<footer [class]="class">
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
<!--				<div class="col-lg-4 col-md-6">-->
<!--					<div class="footer-title footer-mobile-title">-->
<!--						<h4>about</h4>-->
<!--					</div>-->
<!--					<div class="footer-contant">-->
<!--						<div class="footer-logo">-->
<!--							<img src="assets/images/logo.png" alt="logo">-->
<!--						</div>-->
<!--&lt;!&ndash;						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>&ndash;&gt;-->
<!--						<div class="footer-social">-->
<!--                            <ul>-->
<!--                                <li>-->
<!--                                    <a href="https://www.facebook.com/rathnafanhouse1"><i class="fa fa-facebook" aria-hidden="true"></i></a>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <a href="https://www.youtube.com/c/RathnaFanHousePvtLtd"><i class="fa fa-youtube" aria-hidden="true"></i></a>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <a href="https://www.instagram.com/rathnafanhouse/"><i class="fa fa-instagram" aria-hidden="true"></i></a>-->
<!--                                </li>-->
<!--                            </ul>-->
<!--                        </div>-->
<!--					</div>-->
<!--				</div>-->
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Categories</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">Remote Control</a></li>
                                <li><a href="javascript:void(0)">Solar Water Heater</a></li>
                                <li><a href="javascript:void(0)">Water Dispenser</a></li>
                                <li><a href="javascript:void(0)">Kitchen Sinks</a></li>
                                <li><a href="javascript:void(0)">Digital Inverters</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>why we choose</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">Policy</a></li>
                                <li><a href="javascript:void(0)">Gallery</a></li>
                                <li><a href="javascript:void(0)">Contacts</a></li>
                                <li><a href="javascript:void(0)">Customer Care</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div *ngFor="let strinforma of storeinformation let i = index let first = first ;let last = last;">
							<div *ngIf="first">
						<div class="footer-title">
<!--						    <h4>store information 1</h4>-->
						    <h4>{{strinforma.Heading}}</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list" >
						        <li><i class="fa fa-building"></i>{{strinforma.Name}}</li>
						        <li><i class="fa fa-map-marker"></i>{{strinforma.Address}}</li>
						        <li><i class="fa fa-phone"></i>{{strinforma.Phonenumbers}}</li>
						        <li><i class="fa fa-envelope-o"></i>{{strinforma.Mailid}}</li>
<!--						        <li><i class="fa fa-map-marker"></i>{{strinforma.Name}} RATHNA FAN HOUSE PVT.LTD</li>-->
<!--						        <li><i class="fa fa-map-marker"></i>{{strinforma.Address}}36, Rajabather Street, T.Nagar Chennai 600 017.</li>-->
<!--						        <li><i class="fa fa-phone"></i>{{strinforma.Phonenumbers}}Call Us: 28155535 / 28153068 / 28151266 / 28152721 / 49252627.</li>-->
<!--						        <li><i class="fa fa-envelope-o"></i>{{strinforma.Mailid}}Email Us: <a href="mailto:rathnafanhouse@gmail.com">rathnafanhouse@gmail.com</a></li>-->
						    </ul>
						</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div *ngFor="let strinforma of storeinformation let i = index let first = first ;let last = last;">
							<div *ngIf="last">
							<div class="footer-title">
<!--						    <h4>store information 2</h4>-->
						    <h4>{{strinforma.Heading}}</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list" >
								<li><i class="fa fa-building"></i>{{strinforma.Name}}</li>
								<li><i class="fa fa-map-marker"></i>{{strinforma.Address}}</li>
								<li><i class="fa fa-phone"></i>{{strinforma.Phonenumbers}}</li>
								<li><i class="fa fa-envelope-o"></i>{{strinforma.Mailid}}</li>
						    </ul>
<!--						    <ul class="contact-list">-->
<!--						        <li><i class="fa fa-map-marker"></i>RATHNA COOLS PVT.LTD</li>-->
<!--						        <li><i class="fa fa-map-marker"></i>36/1, Rajabather Street, T.Nagar Chennai 600 017.</li>-->
<!--						        <li><i class="fa fa-phone"></i>Call Us: 28155535 / 28153068 / 28151266 / 28152721 / 49252627.</li>-->
<!--						        <li><i class="fa fa-envelope-o"></i>Email Us: <a href="mailto:rathnafanhouse@gmail.com">rathnafanhouse@gmail.com</a></li>-->
<!--						    </ul>-->
						</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p>Copyright <i class="fa fa-copyright" aria-hidden="true"></i>  2022 --- All Rights Reserved</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <p>Designed & Developed by  NAETHRA Technologies Pvt Ltd </p>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->
