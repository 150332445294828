import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient, private toastrService: ToastrService) { }

 /*
 ----------------------------------------------------------
 ---------------  LOGIN DETAILS CHECK  --------------------
 -----------------------------------------------------------
*/

  // LOGIN DETAILS CHECK

  /**
   *  Get Login Details Check
   *
   */

  loginDetailsCheck(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/GetUserLogin?Username='+ param.userName+ '&Password=' +
        param.password);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/GetUserLogin?Username=kanagaraj.sk@naethra.com&Password=kanagu123

  }


  /*
  ----------------------------------------------------------
  ---------------  LOGIN USER DETAILS FETCH  --------------------
  -----------------------------------------------------------
 */

  // LOGIN USER DETAILS FETCH

  /**
   *  Get Login User Details Fetch
   *
   */

  loginUserDetailsFetch(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/GetUserlogindetails?emailid='+ param.mailid);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/GetUserlogindetails?emailid=test1@gmail.com
  }



  /*
  ----------------------------------------------------------
  ---------------  USER REGISTRATION DETAILS SAVE  --------------------
  -----------------------------------------------------------
 */

  // USER REGISTRATION DETAILS SAVE

  /**
   *  Get User Registration Details Save
   *
   */

  userRegistrationSave(param): Observable<any> {
    // console.log(param);
    // Frontend
    // email:"test@gmail.com"
    // gender:"Male"
    // landlineno:"044121234"
    // mobileno:"1234567890"
    // name:"test"
    // password:"12345"
    // Backend
    // registration.custname = custname;
    // registration.Password = Encrypt(Password);
    // registration.Mobileno = Mobileno;
    // registration.Phoneno = Phoneno;
    // registration.gender = gender;
    // registration.EmailId = EmailId;
    // registration.chknews = chknews;
    return this.http.get(environment.baseUrl + 'Rfhhome/InsertregistrationDetails?custname='+ param.name+
        '&Password=' + param.password+
        '&Mobileno=' + param.mobileno+
        '&Phoneno=' + param.landlineno+
        '&gender=' + param.gender+
        '&EmailId=' + param.email+
        '&chknews=' + 'N');

  }



 /*
 ----------------------------------------------------------
 ---------------  FORGOT PASSWORD DETAILS CHECK  --------------------
 -----------------------------------------------------------
*/

  // FORGOT PASSWORD CHECK

  /**
   *  Get Forgot Password Check
   *
   */

  forgotPasswordchk(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/Getforgetpassword?emailid='+ param.email+ '&random=' +
        param.random);

  }



 /*
 ----------------------------------------------------------
 ---------------  CHANGE PASSWORD DETAILS SAVE  --------------------
 -----------------------------------------------------------
*/

  // CHANGE PASSWORD DETAILS SAVE

  /**
   *  Change Password Details Save
   *
   */

  changePasswordSave(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/ChangePassword?emailid='+ param.emailid+ '&password=' +
        param.password+ '&random=' + param.random);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/ChangePassword?emailid=kanaguselva55@gmail.com&password=123444&random=dfghhh
  }



}
