import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { environment } from '../../../environments/environment';

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  // public Currency = { name: 'Dollar', currency: 'USD', price: 1 } // Default Currency
  public Currency = { name: 'Indian Rupee', currency: 'INR', price: 1 , savingamount: '' } // Default Currency
  public OpenCart: boolean = false;
  public Products

  public ProductBanner

  constructor(private http: HttpClient,
    private toastrService: ToastrService) { }



  /*
 -------------------------------------------------------------
 ---------------  HOME PAGE PRODUCT FETCH  -------------------
 -------------------------------------------------------------
*/

  // Product Fetch

  /**
   *  Get Home page product list
   *
   */

  getHomepageProductList(): Observable<any> {
    return this.http.get(environment.baseUrl + 'Rfhhome/getfeatueitemdetails');
  }

  /*
 ---------------------------------------------------------------------
 ---------------  HOME PAGE SPECIAL PRODUCT FETCH  -------------------
 ---------------------------------------------------------------------
*/
  // Special Product Fetch
  /**
   *  Get Home page Special product list
   *
   */

  getHomepageSpecialProductList(): Observable<any> {
    return this.http.get(environment.baseUrl + 'Rfhhome/getspecialitemdetails');
  }

  /*
 ---------------------------------------------------------------------
 ---------------  HOME PAGE SPECIAL PRODUCT FETCH  -------------------
 ---------------------------------------------------------------------
*/

  // Special Product Fetch

  /**
   *  Get Home page Special product list
   *
   */

  // // getleftsidebarProductList(params): Observable<any> {
  // private get products(): Observable<Product[]> {
  //    this.Products = this.http.get(environment.baseUrl + 'Rfhhome/getwebsiteproductsbasedproductid?productid='+ localStorage['prodcateogry']);
  //    // this.Products = this.http.get(environment.baseUrl + 'Rfhhome/getwebsiteproductsbasedproductid?productid=1');
  //
  //
  //   // this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));
  //   // this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next["productitemsdetailslist"]) });
  //    // this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  //   console.log("prodeuctservice", this.Products);
  //   // console.log("prodeuctservice111", localStorage['products']);
  //   return this.Products;
  // }

  // public get getleftsidebarProductListdet(): Observable<Product[]> {
  //   return this.products;
  // }

  /*
----------------------------------------------------------------------------------------
---------------  PRODUCT PAGE PRODUCT IMAGE AND PRODUCT TITLE FETCH  -------------------
----------------------------------------------------------------------------------------
*/

  // Product page product image and product title Fetch

  /**
   *  Get Product page product image and product title Fetch
   *
   */

  getProducttitleimage(params): Observable<any> {
    return this.http.get(environment.baseUrl + 'Rfhhome/Getproductbanner?productid='+ params);
  }

  /*
------------------------------------------------------------------------------------
---------------  PRODUCT LEFT SIDEBAR PAGE PRODUCT REVIEW FETCH  -------------------
------------------------------------------------------------------------------------
*/

  // Product -left-sidebar page product review Fetch

  /**
   *  Get Product-left-sidebar page product review Fetch
   *
   */

  getProductreview(params): Observable<any> {
    return this.http.get(environment.baseUrl + 'Rfhhome/GetRatingReviewDetails?pdid='+ params);
  }


  /*
------------------------------------------------------------------------------------
---------------  PRODUCT LEFT SIDEBAR PAGE FREQUENTLY BOUGHT TOGETHER FETCH  -------------------
------------------------------------------------------------------------------------
*/

  // Product -left-sidebar page Frequently bought together Fetch

  /**
   *  Get Product-left-sidebar page Frequently bought together Fetch
   *
   */

  getProductFrequentlyboughttogether(params): Observable<any> {
    // return this.http.get(environment.baseUrl + 'Rfhhome/GetAccessoriesMappingdetails?pdid='+ params);
    return this.http.get(environment.baseUrl + 'Rfhhome/GetAccessoriesMappingcombooffer?pdid='+ params);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/GetAccessoriesMappingcombooffer?pdid=37497
  }

  /*
------------------------------------------------------------------------------------
---------------  PRODUCT LEFT SIDEBAR PAGE CHOICE GIFT FETCH  -------------------
------------------------------------------------------------------------------------
*/

  // Product -left-sidebar page Choice Gift Fetch

  /**
   *  Get Product-left-sidebar page Choice Gift Fetch
   *
   */

  getProductChoiceGiftFetch(params): Observable<any> {
    return this.http.get(environment.baseUrl + 'Rfhhome/GetAccessoriesMappinggift?pdid='+ params);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/GetAccessoriesMappinggift?pdid=37497
  }

  /*
-------------------------------------------------------------------------------------
---------------  PRODUCT LEFT SIDEBAR PAGE PRODUCT REVIEW INSERT  -------------------
-------------------------------------------------------------------------------------
*/

  // Product -left-sidebar page product review Insert

  /**
   *   Product-left-sidebar page product review Insert
   *
   */

  insertProductreview(param): Observable<any> {
    // console.log(param);
    // loginemailid:"232321"
    // pdid:37497
    // ratingcount:"33232"
    // ratingstatus:"33232"
    // review:"sdds"
    // reviewcomment:"33232"
    // reviewername:"wewq"
    // userid:undefinedpdid
    return this.http.get(environment.baseUrl + 'Rfhhome/InsertRatingandReview?pdid='+ param.pdid+ '&ratingcount=' +
        param.ratingcount + '&ratingstatus=' + param.ratingstatus + '&review=' +
        param.review + '&reviewername=' + param.reviewername + '&userid=' +
        param.userid + '&loginemailid=' + param.loginemailid + '&reviewcomment=' +
        param.reviewcomment);
    // http://localhost:53623/api/Rfhhome/InsertRatingandReview?pdid=42065&ratingcount=5&ratingstatus=1&review=good&reviewername=admin&userid=1758871&loginemailid=test@gmail.com&reviewcomment=very nice
  }

  /*
-------------------------------------------------------------------------------------
---------------  SAVE ADD TO CART DATA  -------------------
-------------------------------------------------------------------------------------
*/
  // Save add to cart data
  /**
   *   save add to cart data
   *
   */
  saveAddtocart(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/Insertaddtocard?productdetails='+ param);
    // http://localhost:53623/api/Rfhhome/Insertaddtocard?productdetails=37497,2000,2,2000,41144@1@10@TFW3BMd.png,123,145:37497,2000,2,2000,41144@1@10@TFW3BMd.png,123,145
  }

  /*
-------------------------------------------------------------------------------------
---------------  CART DATA FETCH  -------------------
-------------------------------------------------------------------------------------
*/
  // cart data fetch
  /**
   *    cart data fetch
   *
   */

  cartDataFetch(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/Getcartinformation?customerid='+ param);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/Getcartinformation?customerid=2013473
  }

  /*
-------------------------------------------------------------------------------------
---------------  DELETE CART DATA   -------------------
-------------------------------------------------------------------------------------
*/
  // Delete cart data
  /**
   *    delete data fetch
   *
   */
  deletecartData(param): Observable<any> {
    // console.log(param);
    // return this.http.get(environment.baseUrl + 'Rfhhome/deleteaddtocard?productdetails='+ param);
    return this.http.get(environment.baseUrl + 'Rfhhome/deleteaddtocard?productdetails='+ param.pdid+ ',' + param.customerid + ',' + param.addtocardid);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/deleteaddtocard?productdetails=41171,222,64;41171,222,65
  }

  /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE USER DETAILS FETCH   -------------------
-------------------------------------------------------------------------------------
*/

  // Checkout page user details fetch

  /**
   *    checkout page user details fetch
   *
   */

  checkoutpageUserFetch(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/GetUserinfo?emailid='+ param);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/GetUserinfo?emailid=kanaguselva55@gmail.com
  }

  /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE USER DETAILS UPDATE   -------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page user details update
  /**
   *    checkout page user details update
   *
   */

  checkoutpageUserUpdate(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/Updatepersonaladdress?mode=PD&customername='+ param.PCustName+ '&emailid=' +
        param.PEmailId + '&mobileno=' + param.PMobileNo + '&phoneno=' +
        param.PPhoneNo + '&gender=' + param.PGender  + '&newsfeed=' +
        param.newsfeed);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/Updatepersonaladdress?mode=PD&customername=kanaguselva&emailid=kanaguselva55@gmail.com&mobileno=9655117406&phoneno=9655117406&gender=M&newsfeed=N
  }

  /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE AREA LIST FETCH   -------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page area list fetch
  /**
   *    checkout page area list fetch
   *
   */

  checkoutpageAreaListFetch(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/GetUserArea?flag='+ param);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/GetUserArea?flag=WC
  }

  /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE INVOICE ADDRESS UPDATE   -------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page invoice address update
  /**
   *    checkout page invoice address update
   *
   */

  checkoutpageInvoiceaddresUpdate(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/Updateinvoiceaddress?address='+ param.IAddress+ '&areaid=' +
        param.IAreaid + '&beforepurchased=' + param.beforepurchased + '&references=' +
        param.references + '&outsidechennai=' + param.ILocation  + '&pincode=' +
        param.IPincode + '&emailid=' + param.emailid  + '&mode=' + param.mode);
    // http://localhost:53623/api/Rfhhome/Updateinvoiceaddress?address=no 24 ooty main road neer rose gardan ooty&areaid=12&beforepurchased=s&references=no&outsidechennai=i&pincode=643201&emailid=kanagu@gmail.com&mode=IA
  }

  /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE GET DELIVERY ADDRESS FETCH   -------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page get delivery address fetch
  /**
   *    checkout page get delivery address fetch
   *
   */

  checkoutpageDeliveryAddressListFetch(param): Observable<any> {
    // console.log(param);
    // Getdeliveryaddresslist
    return this.http.get(environment.baseUrl + 'Rfhhome/Getdeliveryaddresslist?emailid=' + param);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/Getdeliveryaddresslist?emailid=kanaguselva55@gmail.com\
  }

  /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE SUBMIT GST NO   -------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page submit gst no
  /**
   *    checkout page submit gst no
   *
   */

  checkoutpageGSTdetailsupdate(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/Updatedecustomergsttin?emailid=' + param.emailid + '&gstin=' + param.gstin + '&gstbusinessname=' + param.gstbusinessname);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/Updatedecustomergsttin?emailid=kanaguselva55@gmail.com&gstin=WECGBTNNQWCC1&gstbusinessname=kanagaraj
  }

  /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE DELIVERY CHARGES DISPLAY   -------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page delivery charges display
  /**
   *    checkout page delivery charges display
   *
   */

  checkoutpageDeliveryChargesDisplay(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/Getdeliverycost?areaid=' + param.areaid);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/Getdeliverycost?areaid=113
  }

  /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE CUSTOMER ID MAIL ID SAVE   -------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page customerid mailid save
  /**
   *    checkout page customerid mailid save
   *
   */
  custidmailidsave(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/insertonlinepurchasemasteranddetails?customermailid=' + param.customermailid+
        '&customerid=' + param.customerid + '&onlineTransactionId=' + param.onlineTransactionId +
        '&daddress=' + param.daddress+ '&dareaid=' + param.dareaid+ '&landmark=' + param.landmark+
        '&dpincode=' + param.dpincode+ '&doutsidechennai=' + param.doutsidechennai+ '&Bussinessname=' + param.Bussinessname+
        '&gstno=' + param.gstno+ '&address=' + param.address+ '&areaid=' + param.areaid+ '&isgst=' + param.isgst);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/insertonlinepurchasemasteranddetails?customermailid=kanagu@gmail.com&customerid=2013475&Address
  }

  /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE GET ONLINE USER DETAILS   -------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page get online user details
  /**
   *    checkout page get online user details
   *
   */
  getonlineUserDetails(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/getonlineuserdetails?restrackid=' + param);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/getonlineuserdetails?restrackid=1234567890
  }

 /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE INSERT TERMSANDCONDITIONS DETAILS  -------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page insert termsandconditions details
  /**
   *    checkout page insert termsandconditions details
   *
   */
  insertTermsandcondition(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/insertonlinetermsandcondition?EntityId=' + param.EntityId  + '&ProductId='+ param.ProductId+ '&BrandId=' +
        param.BrandId + '&Description=' + param.Description + '&TransactionId=' + param.TransactionId);
        // param.BrandId + '&Description=' + param.Description + '&TransactionId=' + param.TransactionId);
      // http://localhost:53623/api/Rfhhome/insertonlinetermsandcondition?EntityId=1&ProductId=2&BrandId=4&Description=ggggggg&TransactionId=1234567hyu
  }





  /*
-------------------------------------------------------------------------------------
---------------   CHECK OUT PAGE SAVE CART DATA  -------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page Save cart data
  /**
   *    Checkout page Save cart data
   *
   */
  saveCheckoutPagecart(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/OnlinepurchaseInsertaddtocard?productdetails='+ param);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/OnlinepurchaseInsertaddtocard?productdetails=37497,35590,1,35590,https://rathnafanhouse.co.in/test/Images/WebImages/37497@1@10@0.75 ton.jpg,1357182,305,Air Conditioners,1,MITSUBISHI,7785.3125,28.0,1,0
  }





 /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE CCAVENUE REDIRECT PAGE    -------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page ccavenue redirect page
  /**
   *    checkout page ccavenue redirect page
   *
   */
  getPaymentgatewayRedirectURL(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + "Rfhhome/getccavRequestHandler?Trackid=" + param.Trackid);
    // return this.http.get(environment.baseUrl + "Rfhhome/getccavRequestHandler?Trackid=" + param.Trackid+ "&redirect_url=" + param.redirect_url + "&cancel_url=" + param.cancel_url);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/getccavRequestHandler?Trackid=1fecc2dbe4014349b0bbbd29c77da8&redirect_url=https://rathnafanhouse.co.in/test/ccavResponseHandler.aspx&cancel_url=https://rathnafanhouse.co.in/test/ccavResponseHandler.aspx
  }

 /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE CCAVENUE REDIRECT PAGE    -------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page ccavenue redirect page
  /**
   *    checkout page ccavenue redirect page
   *
   */

  termsandconditiondatafetch(): Observable<any> {
    return this.http.get(environment.baseUrl + "Rfhhome/getonlinetermsandcondition");
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/getonlinetermsandcondition
  }

  /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE DELIVERY ADDRESS UPDATE   -------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page delivery address update
  /**
   *    checkout page delivery address update
   *
   */

  checkoutpageDeliveryaddresUpdate(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/Updatedeliveryaddress?emailid=' + param.emailid  + '&daddress='+ param.daddress+ '&dareaid=' +
        param.dareaid + '&doutsidechennai=' + param.doutsidechennai + '&dpincode=' +
        param.dpincode + '&outsidechennai=' + param.outsidechennai  + '&landmark=' +
        param.landmark + '&dmode=' + param.dmode  + '&mode=' + param.mode);
  }


  /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE DELIVERY ADDRESS UPDATE   -------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page delivery address update
  /**
   *    checkout page delivery address update
   *
   */

  onlineEmailIdSave(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/insertonlinepaymentusermailid?transid=' + param.transid  + '&emailid='+ param.emailid+ '&amount=' +
        param.amount);
    // http://localhost:53623/api/Rfhhome/insertonlinepaymentusermailid?transid=1234frt&emailid=s@gmail.com&amount=1234
  }






  /*
-------------------------------------------------------------------------------------
---------------  CHECK OUT PAGE AREA NAME FETCH   -----------------------------------
-------------------------------------------------------------------------------------
*/
  // Checkout page area name fetch
  /**
   *    checkout page area name fetch
   *
   */

  areaNameFetch(param): Observable<any> {
    // console.log(param);
    return this.http.get(environment.baseUrl + 'Rfhhome/GetAreaname?areaid=' + param);
    // http://localhost:53623/api/Rfhhome/GetAreaname?areaid=1
  }







  /*
    ---------------------------------------------
    ---------------  SEARCH Product  -------------------
    ---------------------------------------------
  */

  //
  /**
   *    Search product fetch
   *
   */

  // searchProduct(param): Observable<any> {
  //   console.log(param);
  //   localStorage['products'] = [];
  //   // return this.http.get(environment.baseUrl + 'Rfhhome/getproductsearch?productid=-1&search='+ param.search);
  //   // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/getproductsearch?productid=-1&search=fan mobile white
  //
  //   this.Products = this.http.get(environment.baseUrl + 'Rfhhome/getproductsearch?productid=-1&search='+ param.search);
  //   this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
  //   this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  //
  //   console.log("prodeuctservice", this.Products);
  //   console.log("prodeuctservice111", localStorage['products']);
  //   // console.log(this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]'))));
  //
  //   return this.Products;
  //
  //
  // }





  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Product
  private get products(): Observable<Product[]> {
    // console.log(environment.baseUrl);
    // this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));
    // this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    // return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));

    // console.log("prodeuctservice111111", localStorage['prodcateogry']);
    // console.log("prodeuctservice111111", localStorage);

    // console.log(environment.baseUrl + 'Rfhhome/getwebsiteproductsbasedproductid?productid='+ localStorage['prodcateogry']);
    // console.log(environment.baseUrl + 'Rfhhome/getwebsiteproductsbasedproductidtest?productid='+ localStorage['prodcateogry']);

    // localStorage.removeItem("products");
    // console.log(localStorage['products']);

      const prodcateogry = localStorage['prodcateogry'];
      let producurl = environment.baseUrl + 'Rfhhome/getwebsiteproductsbasedproductidtest?productid='+ prodcateogry;
      if(prodcateogry == '-1'){
        producurl = environment.baseUrl + 'Rfhhome/getproductsearch?productid=-1&search='+ localStorage['prodsearch'];
      }


    // this.Products = this.http.get(environment.baseUrl + 'Rfhhome/getwebsiteproductsbasedproductid?productid='+ localStorage['prodcateogry']);
    this.Products = this.http.get(producurl);
    // this.Products = this.http.get(environment.baseUrl + 'Rfhhome/getwebsiteproductsbasedproductidtest?productid='+ localStorage['prodcateogry']);
    this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));

    // console.log("prodeuctservice", this.Products);
    // console.log("prodeuctservice111", localStorage['products']);
    // console.log(this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]'))));

    return this.Products;
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    // console.log("ccccat",this.products);
    return this.products;
  }

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {
    return this.products.pipe(map(items => { 
      return items.find((item: any) => { 
        return item.title.replace(' ', '-') === slug; 
      }); 
    }));
  }


  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    // const wishlistItem = state.wishlist.find(item => item.id === product.id)
    const wishlistItem = state.wishlist.find(item => item.pdid === product.pdid)
    if (!wishlistItem) {
      state.wishlist.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in wishlist.');
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    // const compareItem = state.compare.find(item => item.id === product.id)
    const compareItem = state.compare.find(item => item.pdid === product.pdid)
    if (!compareItem) {
      state.compare.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in compare.');
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.cart);
      observer.complete();
    });
    // console.log(itemsStream);
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Cart
  // public addToCart(product): any {
  public addToCart(product: any,combooffer: any = [],choicegift: any = []) {
    // console.log(product);
    // console.log(combooffer);
    // console.log(choicegift);
    // console.log(state.cart);
    // const cartItem = state.cart.find(item => item.id === product.id);
    const cartItem = state.cart.find(item => item.pdid === product.pdid);
    // console.log(item.id);
    // console.log(state.cart.find(item => item.pdid === product.pdid));
    // console.log(product.pdid);
    // console.log(cartItem);
    const qty = product.quantity ? product.quantity : 1;
    // console.log(qty);
    const qty1 = combooffer.quantity ? combooffer.quantity : 1;
    // console.log(qty1);
    const qty2 = choicegift.quantity ? choicegift.quantity : 1;
    // console.log(qty2);
    const items = cartItem ? cartItem : product;
    // console.log(items);
    const stock = this.calculateStockCounts(items, qty);
    // console.log(stock);
    
    if(!stock) return false

    // console.log("CART11111",localStorage["cartItems"]);
    // console.log("STATE CART11111",state.cart);

    // this.product.push(combooffer);
    //
    // console.log(product);

    if (cartItem) {
        cartItem.quantity += qty    
    } else {
      state.cart.push({
        // ...product,...combooffer,...choicegift,
        ...product,
        // ...product,...combooffer,
        // choicegift: choicegift,
        quantity: qty
      })
      if(combooffer.length > 0) {
        for(let i in combooffer ){
          // console.log(i);
          state.cart.push({
            ...combooffer[i],
            quantity: qty1
          })
        }
    }
    if(choicegift.length > 0) {
      for(let i in choicegift ){
        // console.log(i);
        state.cart.push({
          ...choicegift[i],
          quantity: qty2
        })
      }
    }

    }


    // console.log(product);

    // console.log("STATE CART222222222",state.cart);

    this.OpenCart = true; // If we use cart variation modal
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    // console.log("CART22222",localStorage["cartItems"]);
    return true;
  }

  // Update Cart Quantity
  public updateCartQuantity(product: Product, quantity: number): Product | boolean {
    // console.log("Step1",localStorage['cartItems']);
    return state.cart.find((items, index) => {
      // if (items.id === product.id) {
      if (items.pdid === product.pdid) {
        const qty = state.cart[index].quantity + quantity
        const stock = this.calculateStockCounts(state.cart[index], quantity)
        if (qty !== 0 && stock) {
          state.cart[index].quantity = qty
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        // console.log("Step2",localStorage['cartItems']);
        return true
      }
    })
  }

    // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity
    const stock = product.stock
    if (stock < qty || stock == 0) {
      this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.');
      return false
    }
    return true
  }

  // Remove Cart items
  public removeCartItem(product: Product,i=''): any {
    const index = state.cart.indexOf(product);
    state.cart.splice((i=='')?index:i, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true
  }

  // Total amount 
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(map((product: Product[]) => {
      // console.log(product);
      return product.reduce((prev, curr: Product) => {
        let price = curr.price;
        if(curr.discount) {
          price = curr.price - (curr.price * curr.discount / 100)
        }
        // console.log((prev + price * curr.quantity) * this.Currency.price);
        return (prev + price * curr.quantity) * this.Currency.price;
      }, 0);
    }));
  }

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    // console.log(this.products);
    // console.log(filter);
    // console.log(product);
    // const filtprod = this.products.pipe(map(product =>
    return this.products.pipe(map(product =>
      product.filter((item: Product) => {
        // console.log(product);
        if (!filter.length) return true
        const Tags = filter.some((prev) => { // Match Tags
          // console.log("PREV",prev);
          // console.log("itemtags",item.tags);
          if (item.tags) {
            if (item.tags.includes(prev)) {
              return prev
            }
          }
        })
        return Tags
      })
    ));
    // console.log(filtprod);
    // return filtprod;


    // return this.products.pipe(map(product =>
    //     product.filter((item: Product) => {
    //       if (!filter.length) return true
    //       const Tags = filter.some((prev) => { // Match Tags
    //         if (item.tags) {
    //           if (item.tags.includes(prev)) {
    //             return prev
    //           }
    //         }
    //       })
    //       return Tags
    //     })
    // ));
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {

    if(payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      })
    } 
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
  // public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 32) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) { 
      currentPage = 1; 
    } else if (currentPage > totalPages) { 
      currentPage = totalPages; 
    }
    
    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if(currentPage < paginateRange - 1){
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage =  currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

}
