<!--<app-breadcrumb [title]="'Change Password'" [breadcrumb]="'Change Password'"></app-breadcrumb>-->
<!-- section start -->
<section class="register-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>change password</h3>
                <div class="theme-card">
                    <form class="theme-form" [formGroup] = "changePasswordForm">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="userid">User Id</label>
<!--                                <input type="text" class="form-control" id="userid" formControlName="userid"  required="" readonly="readonly">-->
                                <input type="text" class="form-control" id="userid" formControlName="userid"  required="" readonly="readonly" style="background-color: #e9ecef">
<!--                                <input type="text" class="form-control" id="userid" formControlName="userid"  required="" disabled>-->
                            </div>
                            <div class="col-md-6">
                                <label for="oldpassword">Old Password</label> <span style="color: red;"> *</span>
                                <input type="password" class="form-control" id="oldpassword" formControlName="oldpassword" placeholder="Please enter your old password" required="" [ngClass]="{ 'is-invalid': submitted && chpass.oldpassword.errors}" (change)="Removewrgpass()">
                                <div *ngIf="(submitted && chpass.oldpassword.errors )" class="invalid-feedback">
                                    <div  *ngIf="chpass.oldpassword.errors.required && !wrongPassword">Old password is required</div>
                                    <div  *ngIf="chpass.oldpassword.errors.minlength && !wrongPassword">Password should be 6 characters length</div>
                                </div>
                                <p *ngIf="wrongPassword  && Removewrgpassvar" style="color: red;" > Wrong password !</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="newpassword">New Password</label> <span style="color: red;"> *</span>
                                <input type="password" class="form-control" id="newpassword" formControlName="newpassword" placeholder="Please type your new password" required="" [ngClass]="{ 'is-invalid': submitted && chpass.newpassword.errors}">
                                <div *ngIf="submitted && chpass.newpassword.errors" class="invalid-feedback">
                                    <div  *ngIf="chpass.newpassword.errors.required">Please enter your new password</div>
                                    <div  *ngIf="chpass.newpassword.errors.minlength">Password should be 6 characters length</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="retypepassword">Re-Type Password</label> <span style="color: red;"> *</span>
                                <input type="password" autocomplete="off" class="form-control" id="retypepassword" formControlName="retypepassword" placeholder="Please enter your new password again" required="" [ngClass]="{ 'is-invalid': submitted && chpass.retypepassword.errors}">
                                <div *ngIf="submitted && chpass.retypepassword.errors" class="invalid-feedback">
                                    <div *ngIf="chpass.retypepassword.errors.required">Re-type your new password</div>
                                    <div *ngIf="chpass.retypepassword.errors.mustmatch">Password mismatch !</div>
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-solid" (click)="SubmitChangePwd()">Submit</button> &nbsp; 
                        <button (click)="clearChangePwd()" class="btn btn-solid">Clear</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->
