<div class="main-navbar">
  <div id="mainnav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">
      <li class="back-btn">
        <div class="mobile-back text-end" (click)="mainMenuToggle()">
          <span>Back</span>
              <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
        </div>
      </li>
      <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu">
        <!-- Sub -->
<!--        <a href="javascript:void(0)" class="nav-link" *ngIf="menuItem.type === 'sub'" -->
<!--        <a href="javascript:void(0)" class="nav-link 1111111111111111"-->
<!--            (click)="toggletNavActive(menuItem)">-->
                <a [routerLink]="menuItem.path" classs="1111111111111111" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">
<!--          {{ menuItem.title | translate }}-->
          {{ menuItem.menuname | translate }}
<!--          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>-->
<!--          <span class="sub-arrow" *ngIf="menuItem.children"></span>-->
          <span class="sub-arrow" *ngIf="menuItem.submenu"></span>
        </a>
        <!-- Link -->
        <a [routerLink]="menuItem.path" classs="7777777777777777" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link"
          *ngIf="menuItem.type === 'link'">
<!--          {{ menuItem.title }}-->
          {{ menuItem.menuname }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
<!--          <span class="sub-arrow" *ngIf="menuItem.children"></span>-->
          <span class="sub-arrow" *ngIf="menuItem.submenu"></span>
        </a>
        <!-- External Link -->
        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extLink'">
<!--          {{ menuItem.title }}-->
          {{ menuItem.menuname }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
<!--          <span class="sub-arrow" *ngIf="menuItem.children"></span>-->
          <span class="sub-arrow" *ngIf="menuItem.submenu"></span>
        </a>
        <!-- External Tab Link -->
        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extTabLink'">
<!--          {{ menuItem.title }}-->
          {{ menuItem.menuname }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
<!--          <span class="sub-arrow" *ngIf="menuItem.children"></span>-->
          <span class="sub-arrow" *ngIf="menuItem.submenu"></span>
        </a>

        <!-- 2nd Level Menu -->
<!--        <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">-->
        <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.submenu">
<!--          <li *ngFor="let childrenItem of menuItem.children">-->
          <li *ngFor="let childrenItem of menuItem.submenu">
<!--              {{childrenItem.submenuid}}-->
<!--              {{childrenItem.submenuname}}-->
            <!-- Sub -->
<!--            <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" -->
<!--              <a href="#{{ childrenItem.path }}" >-->
                              <!--                (click)="toggletNavActive(childrenItem)" class="22222222222">-->
<!--              {{ childrenItem.title | translate }}-->

<!--           <a class="111111111" *ngIf="childrenItem.submenuname === 'Gallery' || childrenItem.submenuname === 'Who are We?' || childrenItem.submenuname === 'Why Rfh'" [routerLink]="childrenItem.path" >-->
           <a class="111111111" *ngIf="menuchecck(childrenItem) != -1" [routerLink]="childrenItem.path" >
                              {{ childrenItem.submenuname | translate }}
                              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
                <!--              <span class="sub-arrow" *ngIf="childrenItem.children"></span>-->
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
<!--           <a class="2222222" *ngIf="childrenItem.submenuname != 'Gallery' && childrenItem.submenuname != 'Who are We?' && childrenItem.submenuname != 'Why Rfh'" [routerLink]="['/shop/collection/left/sidebar/']" [queryParams]="{ category: childrenItem.submenuname,id:childrenItem.submenuid}">-->
           <a class="2222222" *ngIf="menuchecck(childrenItem) == -1" [routerLink]="['/shop/collection/left/sidebar/']" [queryParams]="{ category: childrenItem.submenuname,id:childrenItem.submenuid}">
                              {{ childrenItem.submenuname | translate }}
                              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
                <!--              <span class="sub-arrow" *ngIf="childrenItem.children"></span>-->
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- Link -->
            <a [routerLink]="childrenItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              *ngIf="childrenItem.type === 'link' ">
<!--              {{ childrenItem.title | translate }}-->
              {{ childrenItem.submenuname | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
<!--              <span class="sub-arrow" *ngIf="childrenItem.children"></span>-->
              <span class="sub-arrow" *ngIf="childrenItem.submenuname"></span>
            </a>
            <!-- External Link -->
            <a href="{{ childrenItem.path }}"
              *ngIf="childrenItem.type === 'extLink' ">
<!--              {{ childrenItem.title | translate }}-->
              {{ childrenItem.submenuname | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
<!--              <span class="sub-arrow" *ngIf="childrenItem.children"></span>-->
              <span class="sub-arrow" *ngIf="childrenItem.submenu"></span>
            </a>
            <!-- External Tab Link -->
            <a href="{{ childrenItem.path }}" target="_blank"
              *ngIf="childrenItem.type === 'extTabLink' ">
<!--              {{ childrenItem.title | translate }}-->
              {{ childrenItem.submenuname | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
<!--              <span class="sub-arrow" *ngIf="childrenItem.children"></span>-->
              <span class="sub-arrow" *ngIf="childrenItem.submenu"></span>
            </a>

            <!-- 3rd Level Menu -->
<!--            <ul *ngIf="childrenItem.children" [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu">-->
            <ul *ngIf="childrenItem.submenu" [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu">
<!--              <li *ngFor="let childrenSubItem of childrenItem.children">-->
              <li *ngFor="let childrenSubItem of childrenItem.submenu">
                <!-- Link -->
                <a [routerLink]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                  *ngIf="childrenSubItem.type === 'link' ">
<!--                  {{ childrenSubItem.title | translate }}-->
                  {{ childrenSubItem.submenuname | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                <!-- External Link -->
                <a href="{{ childrenSubItem.path }}"
                  *ngIf="childrenSubItem.type === 'extLink' ">
<!--                  {{ childrenSubItem.title | translate }}-->
                  {{ childrenSubItem.submenuname | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                <!-- External Tab Link -->
                <a href="{{ childrenSubItem.path }}" target="_blank"
                  *ngIf="childrenSubItem.type === 'extTabLink' ">
<!--                  {{ childrenSubItem.title | translate }}-->
                  {{ childrenSubItem.submenuname | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>

<!--        <div class="mega-menu-container" *ngIf="menuItem.megaMenu && menuItem.children">-->
        <div class="mega-menu-container" *ngIf="menuItem.megaMenu && menuItem.children">
          <div class="container">
            <div class="row">
<!--              <div class="col mega-box" *ngFor="let childrenItem of menuItem.children">-->
              <div class="col mega-box" *ngFor="let childrenItem of menuItem.children">
                <div class="link-section">
                  <div class="menu-title">
                    <h5>
<!--                        {{ childrenItem.title | translate }}-->
                        {{ childrenItem.submenuname | translate }}
<!--                        <span class="sub-arrow" *ngIf="childrenItem.children"></span>-->
                        <span class="sub-arrow" *ngIf="childrenItem.children"></span>
                    </h5>
                  </div>
                  <div class="menu-content opensubmegamenu">
<!--                    <ul *ngIf="childrenItem.children">-->
                    <ul *ngIf="childrenItem.submenu">
<!--                      <li *ngFor="let childrenSubItem of childrenItem.children">-->
                      <li *ngFor="let childrenSubItem of childrenItem.submenu">
                        <!-- Sub -->
                          <a href="javascript:void(0)" *ngIf="childrenSubItem.type === 'sub'">
<!--                            {{ childrenSubItem.title | translate }}-->
                            {{ childrenSubItem.submenuname | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          <!-- Link -->
                          <a [routerLink]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            *ngIf="childrenSubItem.type === 'link' ">
<!--                            {{ childrenSubItem.title | translate }}-->
                            {{ childrenSubItem.submenuname | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          <!-- External Link -->
                          <a href="{{ childrenSubItem.path }}"
                            *ngIf="childrenSubItem.type === 'extLink' ">
<!--                            {{ childrenSubItem.title | translate }}-->
                            {{ childrenSubItem.submenuname | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          <!-- External Tab Link -->
                          <a href="{{ childrenSubItem.path }}" target="_blank"
                            *ngIf="childrenSubItem.type === 'extTabLink' ">
<!--                            {{ childrenSubItem.title | translate }}-->
                            {{ childrenSubItem.submenuname | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
