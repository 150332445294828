import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import {ActivatedRoute, Router} from '@angular/router';
// import { HomeComponent } from '../../../rfh-home/rfh-home.module';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;

  constructor(private actroting: ActivatedRoute, private router: Router , public productService: ProductService) {
    this.productService.getProducts.subscribe(product => this.products = product);
    // console.log("Cateogry PAAAA",this.products);
  }

  ngOnInit(): void {

    // console.log("34324324cffd dsfdsfdsf",this.products);

  }

  get filterbyCategory() {
    // console.log(this.products);
    // console.log(JSON.parse(localStorage["brandList"]));
    // const category = [...new Set(this.products.map(product => product.type))]

    const category = [...new Set(this.products.map(product => product.category))]

    // console.log(this.products.map(product => product.type));
    // console.log(this.products.map(product => product.category));
    // console.log(this.products.map(product => product));
    // console.log(this.products.map(product => product.id));
    return category
  }

}
